.col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 2vw 1rem 2vw;
}

.imageWrapper {
  transition: all 0.25s ease-in-out;
}

.col:hover .imageWrapper {
  transform: translateY(-1rem);
}

.span {
  font-weight: 100;
  font-size: 0.75rem;
}
