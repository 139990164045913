.blog {
  margin-block: 7rem;
  max-width: 900px;
}

.row {
  max-width: 1200px;
}

.h3 {
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
}

.articles {
  padding-block: 6rem;
}

.moreArticles {
  margin-block: 7rem;
}
