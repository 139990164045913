.contactWrapper {
  margin-top: -3rem;
}

.contact {
  margin: auto;
  max-width: 1200px;
  padding: 3rem 1rem;
}

.h3 {
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 100;
  letter-spacing: 5px;
  margin: -1rem auto 1rem;
}

.info {
  display: flex;
  flex-direction: column;
}

.download {
  animation: slowSpin 5s ease-in-out infinite alternate-reverse;
  margin-right: 0.5rem;
}

@keyframes slowSpin {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(-1, 1);
  }

  100% {
      transform: scale(1);
  }
}
