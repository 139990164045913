.wrapper {
  border: 1px solid rgb(75,75,75);
  border-radius: 100vw;
  cursor: pointer;
  height: 45px;
  margin: 0 auto;
  position: relative;
  transition: all 1s ease-out;
  transform: translateY(1rem);
  width: 1.5rem;
  z-index: -1;
}

.wrapper:hover {
  border-color: white;
}

.wrapper:hover>* {
  background: lime;
}

.scrolldown {
  bottom: 20px;
  left: 0;
  right: 0;
  height: 20px;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  transform: translateY(0.5rem);
  transition: all 1s ease-out;
  width: 1px;
}

.scrolldown .line {
  background: linear-gradient(to bottom,rgb(75,75,75) 50%,rgba(255, 255, 255, 0) 50%);
  background-position: 0 -20px;
  background-size: 100% 200%;
  display: block;
  animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
  width: 100%;
  height: 100%;
}

@keyframes scrolldown {
  0%{
		background-position: 0 -20px;
	}
	75% {
		background-position: 0 0;
	}
	100%{
		background-position: 0 20px;
  }
}
