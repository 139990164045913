.carousel {
  margin-block: 3rem;
  max-width: 1200px;
}

.item {
  border-radius: 12px;
  height: 18rem;
  overflow-y: hidden;
}

.title {
  font-size: 1.5rem;
  font-weight: 300;
  padding-bottom: 1rem;
}

.text {
  font-size: 1.125rem;
  font-weight: 100;
  margin-inline: 15%;
  padding-bottom: 1rem;
  text-align: start;
}

.figcaption {
  font-weight: 300;
  margin-right: 25%;
}

@media (max-width: 1000px) {
  .carousel {
    margin-block: 3rem;
    
  }
  .figcaption {
    margin-right: 25%;
  }
  .quote {
    margin-inline: 5%;
  }
  .item {
    height: 19rem;
  }
  .text {
    margin-inline: 10%;
  }
}

@media (max-width: 740px) {
  .carousel {
    border-radius: 24px;
  }
  .item {
    height: 20rem;
  }
  .text {
    margin-inline: 10%;
  }
}

@media (max-width: 575px) {
  .item {
    height: 21rem;
  }
  .figcaption {
    margin-right: 10%;
  }
}

@media (max-width: 500px) {
  .item {
    height: 22rem;
  }
}

@media (max-width: 450px) {
  .item {
    height: 24rem;
  }
}

@media (max-width: 400px) {
  .item {
    height: 26rem;
  }
}

@media (max-width: 320px) {
  .item {
    height: 27rem;   
  }
  .text {
    margin: 2% 5%;
  }
}