.scroll {
  background-color: transparent;
  margin: 1.5rem auto 0.5rem auto;
  padding-block: 1rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100vw;
  max-width: 1200px;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none; 
}

.scroll>* {
  display: inline-block;
  padding: 0 3rem;
  text-align: center;
}

.span>* {
  transition: all 0.25s ease-in-out;
}

.scroll:hover .span>.javascript {
  background-color: yellow;
  color: black;
  padding: 0.5rem 0 0 0.5rem;
}

.scroll:hover .span>.react {
  animation: slowspin 5s infinite ease-in-out;
  color: #00d8ff;
}

@keyframes slowspin {
  100% {
    transform: rotate(360deg);
  }
}

.scroll:hover .span>.git {
  color: #F1502F;
}

.scroll:hover .span>.jest {
  color: darkred;
  -webkit-filter: drop-shadow(1px 1px 1px white);
  filter: drop-shadow(1px 1px 1px white);  
}

.scroll:hover .span>.rest {
  color: skyblue;
}

.scroll:hover .span>.seo {
  color: orange;
}

.kanban {
  border-radius: 4px;
}

.scroll:hover .span>.kanban {
  background-color: white;
  color: coral;
}

.scroll:hover .span>.jira {
  color: cornflowerblue;
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black); 
}

.performance {
  border-radius: 50%;
}

.scroll:hover .span>.performance {
  background-color: white;
  color: green;
}

.scroll:hover .span>.docker {
  color: #0db7ed;
}

.scroll:hover .span>.graphql {
  animation: slowspin 5s infinite ease-in-out;
  color: #e535ab;
}

.scroll:hover .span>.bootstrap {
  color: #553C7B;
}

.scroll:hover .span>.html {
  color: #FFA500;
}

.scroll:hover .span>.css {
  color: #264de4;
}

.scroll:hover .span>.node {
  color: #215732;
}

.scroll:hover .span>.mongo {
  color: #4DB33D;
}

.scroll:hover .span>.typescript {
  background-color: black;
  color: #007acc;
}

.scroll:hover .span>.sass {
  color: #cc6699;
}

.scroll:hover .span>.scrum {
  color: cornflowerblue;
}
