.row {
  max-width: 1200px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  max-width: 600px;
  margin-inline: auto;
  overflow: hidden;
  padding-left: clamp(1rem, 10vw, 2rem);
}


.h4 {
  font-size: 2rem;
  font-weight: 300;
}

.h5 {
  font-size: 1.675rem;
  font-weight: 100;
}

.p {
  font-size: 1.25rem;
  font-weight: 100;
}

.buttonWrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.img {
  max-height: 20rem;
}