#dark .text {
  color: white;
}

#light .text {
  color: black;
}

#dark .textNav,
#light .textNav {
  color: white;
}

#dark .textSecondary {
  color: silver;
}

#light .textSecondary {
  color: gray;
}

#dark .textContrast {
  color: silver;
}

#light .textContrast {
  color: rgb(25,25,25);
}

#dark .textSubtle {
  color: gray;
}

#light .textSubtle {
  color: silver;
}

#dark .textBlend {
  color: rgb(50,50,50);
}

#light .textBlend {
  color: ghostwhite;
}

@media only screen and (min-width: 575px) {
  #dark .textNav {
    color: white;
  }

  #light .textNav {
    color: white;
  }
}
