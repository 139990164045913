.contrast {
  transition: background-color 0.5s ease-in-out;
}

.about {
  max-width: 1200px;
  margin-bottom: 5rem;
}

.profilepic {
  border-radius: 100vw;
  max-width: 50vw;
  transform: translate(50%, 0);
  width: 20rem;
  z-index: -1;
}

.h2 {
  letter-spacing: 0.25rem;
}

.section {
  background: rgba(0,0,0,0.65);
  border-radius: 2px;
  color: white;
  font-weight: 100;
  padding: 1rem;
  max-width: 900px;
  margin: 0 auto;
}

.p {
  font-size: 1.125rem;
}

.pReplace {
  margin-left: 1rem;
}

.row {
  max-width: 1200px;
}

.col {
  padding-inline: 5%;
}
 .h4 {
  font-weight: 400;
 }

.imageP {
  font-size: 1rem;
  font-weight: 100;
}

@media (max-width: 950px) { 
  .col {
    padding-inline: 2%;
  }  
}

@media (max-width: 660px) and (min-width: 575px) { 
  .h4 {
    font-size: 1rem;
  }
  .imageP {
    font-weight: 100;
    font-size: 1rem;
  }
}

@media (max-width: 300px) { 
  .h4 {
    font-size: 1rem;
    margin-top: -3rem;
  }
  .imageP {
    font-weight: 100;
    font-size: 1rem;
  }
}