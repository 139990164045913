.topWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding-block: 1rem;
  position: relative;
  z-index: 12;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-right: 1.5rem;
}

.socials>* {
  color: silver;
  margin: 0.25rem;
}

.github, .twitter, .linkedin, .youtube {
  transition: color 0.1s ease-out;
}

.github, .twitter {
  border-radius: 50%;
}

.linkedin:hover {
  color: #0072b1;
}
.github:hover {
  background: linear-gradient(#0057b7, transparent, #ffd700);
  color: rgba(255,255,255,0.125);
}
.youtube:hover {
  color: #FF0000;
}
.twitter:hover {
  color: #1DA1F2
}

.cbp {
  cursor: crosshair;
  display: flex;
  font-style: italic;
  letter-spacing: .5rem;
  text-shadow: 1px 1px 1px black;
}

.cbp>span {
  display: flex;
}

.cbp>span>div {
  transition: transform 0.1s ease-out;
}

.cbp>span>div:hover {
  transform: translateY(-0.5rem);
}

.cbp>span>div:hover + div {
  transform: translateY(0.25rem);
}

.cbpCode {
  color:silver;
}

.cbpBy {
  color: #00857c;
}

.cbpPete {
  color: lime;
}

@media (min-width: 575px) {
  .github {
    /* color: white; */
  }
}

@media (max-width: 575px) {
  .socials {
    height: 0;
    position: relative;
    flex-direction: column;
    z-index: 12;
  }
}

@media (max-width: 300px) {
  .cbp {
    margin-left: -1rem;
  }
  .socials {
    transform: translate(50%, 0);
  }
}
