.hoverEffect {
  transition: all 0.25s ease-in-out;
}

.hoverEffect:hover {
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
}

.hoverEffect:hover * {
  background: radial-gradient(ghostwhite, transparent);
}
