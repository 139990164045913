.row {
  max-width: 1200px;
  padding-bottom: 3rem;
}

.container {
  position: relative;
  overflow: hidden;
}

.container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  max-width: 600px;
  margin-inline: auto;
  overflow: hidden;
  padding-left: clamp(1rem, 10vw, 2rem);
}

.h4 {
  font-size: 2rem;
  font-weight: 300;
}

.p {
  font-size: 1.25rem;
  font-weight: 100;
  padding-inline: 0.5rem;
}

.buttonWrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
