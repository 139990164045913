.contrast {
  padding-block: 6rem;
  transition: background-color 0.5s ease-in-out;
}

.h2 {
  letter-spacing: 0.25rem;
  padding-top: 5rem;
  position: relative;
  z-index: 11;
}

@media (max-width: 575px) {
  .projects {
    margin-top: -5rem;
  }
}
