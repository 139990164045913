@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

a,a:visited {
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
}

.fontMono {
  font-family: 'Roboto Mono', monospace;
}

.textSilver {
  color: silver;
}

.textGray {
  color: gray;
}

.cornflowerBlue {
  color: cornflowerblue;
}

.textShadow {
  text-shadow: 1px 1px 1px black;
}

.textWhite {
  text-shadow: 1px 1px 1px white;
}
