.headerWrapper {
  transition: background-color 0.5s ease-in-out;
  min-height: 100vh;
} 

.headerContent {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 11;
}

.left {
  padding-left: 2rem;
  position: relative;
  z-index: 11;
}

.h1 {
  font-size: clamp(2rem,8vw,8rem);
  font-weight: 300;
  letter-spacing: 10px;
  margin-block: 3rem;
  position: relative;
  z-index: 10;
}

.h1>div,
.h3>div,
.h4,
.h4>span {
  display: flex;
}

.h1>div>div,
.h3>div>div,
.h4>span>div {
  cursor: crosshair;
  margin: -1.5rem -1rem -1.5rem -0.5rem;
  padding: 1.5rem 1rem 1.5rem 0.5rem;
  transition: all 0.1s ease-out;
}

.h1>div>div:hover {
  color: linear-gradient(#e66465, #9198e5);;
}

.h1>div>div:hover {
  text-shadow: 1px 1px 1px lime;
  transform: translateY(-0.5rem);
  -webkit-filter: drop-shadow(2px 2px 2px lime);
  filter: drop-shadow(2px 2px 2px lime);
}

.h3>div>div:hover,
.h4>span>div:hover {
  color: lime;
  transform: translateY(-0.5rem);
}

.h1>div>div:hover + div,
.h3>div>div:hover + div,
.h4>span>div:hover + div {
  transform: translateY(0.25rem);
}

.h3 {
  display: flex;
  color: gray;
  font-weight: 100;
  gap: 0.5rem;
  letter-spacing: 2px;
  line-height: 2rem;
}

.h4 {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  letter-spacing: 1.5px;
  line-height: 2.5rem;
  margin: 0 auto;
  padding: 0 0.25rem 2rem 0.25rem;
  position: relative;
  transform: translate(0, 20%);
  z-index: 12;
}

.right {
  display: flex;
  justify-content: center;
  height: clamp(20rem, 50vw, 35rem);
  overflow: hidden;
  position: relative;
}

.hero {
  object-fit: cover;
  position: absolute;
  width: clamp(15rem, 40vw, 30rem);
}

.float {
  transition: animation 0.5s ease-in-out;
}

.float1 {
  animation: float1 4s infinite alternate 2s;
}

.float2 {
  animation: float1 4s infinite alternate-reverse 1.5s;
  transform: scale(0.8) translateY(-0.75rem);
}

.float3 {
  animation: float1 5s infinite alternate 2.25s;
}

.float4 {
  animation: float2 3s infinite alternate-reverse 1s;
  transform: scale(0.9) translateY(-0.5rem);
}

.float5 {
  animation: float2 3s infinite alternate 1.25s;
}

@keyframes float1 {
  0% {
    transform: scale(1) translateY(0rem);
  }
  50% {
    transform: scale(0.9) translateY(-0.375rem);
  }
  100% {
    transform: scale(0.8) translateY(-0.75rem);
  }
}

@keyframes float2 {
  0% {
    transform: scale(1) translateY(0rem);
  }
  50% {
    transform: scale(0.95) translateY(-0.25rem);
  }
  100% {
    transform: scale(0.9) translateY(-0.5rem);
  }
}

@media (max-width: 800px) and (min-width: 575px) {
  .right {
    margin-top: 10%;
  }
}

@media (max-width: 575px) {
  .left {
    height: 25vw;
  }
  .h1 {
    font-size: 10vw;
    letter-spacing: 10px;
    margin-block: 1.5rem;
  }
  .right {
    margin-top: 10rem;
    margin-left: 1rem;
  }
}

@media (max-width: 375px) {
  .h1 {
    letter-spacing: 5px;
    margin-block: 1rem;
  }
}

@media (max-width: 325px) {
  .h1 {
    margin-left: -1rem;
    letter-spacing: 6px;
  }
  .h3 {
    transform: translate(-7%, 0);
  }
  .right {
    margin-top: 15rem;
  }
}

@media (orientation: portrait) and (min-height: 800px) {
  .left {
    padding-top: clamp(1rem, 10vh, 3rem);
  }
  .right {
    margin-top: clamp(10rem, 30vh, 20rem);
  }
}

@media (orientation: portrait) and (min-width: 750px) {
  .headerWrapper {
    min-height: unset;
  }
  .right {
    margin: auto;
  }
}

@media (orientation: landscape) and (min-height: 625px) {
  .left {
    padding-top: clamp(2.5rem, 20vh, 5rem);
    
  }
}

@media (orientation: landscape) and (min-height: 750px) {
  .left {
    padding-top: clamp(3rem, 25vh, 10rem);
  }
}

@media (orientation: landscape) and (min-height: 1000px) {
  .headerWrapper {
    min-height: unset;
  }
}