.carouselWrapper {
  margin: 5rem 0 3rem 0; 
}

.carousel {
  max-width: 1200px;
}

.quote {
  margin-inline: 15%;
}

.cite {
  margin-right: 25%;
}

@media (max-width: 900px) {
  .quote {
    margin-inline: auto;
  }
  .cite {
    margin-right: 10%;
  }
}