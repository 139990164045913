.row {
  max-width: 1200px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  max-width: 600px;
  margin-inline: auto;
  overflow: hidden;
  padding-left: clamp(1rem, 10vw, 2rem);
}

.icon {
  transition: all 0.5s ease-in-out;
}

.wrapper:hover .icon {
  animation: beat 2s infinite ease-in;
  color: tomato;
  transform: scale(1.75);
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
}

@keyframes beat {
  0%
  {
    transform: scale(1);
  }
  5%
  {
    transform: scale(1.1);
  }
  20%
  {
    transform: scale(1);
  }
  35%
  {
    transform: scale(1.15);
  }
  50%
  {
    transform: scale(1);
  }
  100%
  {
    transform: scale(1);
  }
}

.h5 {
  font-size: 1.5rem;
  font-weight: 300;
}

.p {
  font-size: 1.125rem;
  font-weight: 300;
}

.buttonWrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.imageP {
  font-size: 1.25rem;
  font-weight: 100;
}

@media (min-width: 1000px) {
  .img {
    padding-inline: 3rem;
  }
}

@media (max-width: 767px) {
  .img {
    padding-inline: 2rem;
    padding-bottom: 4rem;
  }
  .wrapper {
    transform: translate(-0.5rem, 0);
  }
}

@media (max-width: 575px) {
  .img {
    padding-inline: 0;
  }
  .imageP {
    font-size: 1rem;
  }
  .buttonWrapper {
    gap: 0;
  }
}

@media (max-width: 350px) {
  .buttonWrapper {
    gap: 2rem;
  }
}