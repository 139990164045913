.row {
  max-width: 1200px;
  padding-block: 3rem;
}

.col {
  border-radius: 2px;
}

.h4 {
  font-weight: 300;
  padding-block: 1rem;
}

.p {
  font-size: 1rem;
  font-weight: 100;
}

 .icon {
  transition: all 0.25s ease-in-out;
 }

 .col:hover .degree {
  color: gold;
  transform: translateY(-1rem);
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
 }

 .col:hover .mobile {
  animation: wiggle 3s infinite ease-in-out;
  color: cornflowerblue;
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
 }

 @keyframes wiggle {
  14% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  21% {
    transform: rotate(0deg);
  }
 }

 .col:hover .code {
  animation: rotateY 6s infinite ease-out;
  color: lime;
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
 }

 @keyframes rotateY {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(-1, 1);
  }
  100% {
      transform: scale(1);
  }
}

 .col:hover .gear {
   animation: slowspin 4s infinite ease-in-out;
  color: cadetblue;
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
 }

 @keyframes slowspin {
  100% {
    transform: rotate(360deg);
  }
 }

 .shield {
  transform: scale(1);
}

 .col:hover .shield {
  animation: beat 2s infinite ease-in;
  color: lightgreen;
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
 }

 @keyframes beat
 {
   0%
   {
     transform: scale(1);
   }
   5%
   {
     transform: scale(1.1);
   }
   20%
   {
     transform: scale(1);
   }
   35%
   {
     transform: scale(1.15);
   }
   50%
   {
     transform: scale(1);
   }
   100%
   {
     transform: scale(1);
   }
 }

 .col:hover .news {
  color: darkgoldenrod;
  transform: translateY(-1rem);
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
 }