.info {
  display: flex;
  flex-direction: row;
}

.ul {
  margin-block: -0.5rem;
  width: fit-content;
}

.li {
  color: silver;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  gap: 1rem;
  list-style: none;
  margin: 1rem auto;
  transform:translate(-2rem, 0);
}

.li a {
  color: silver;
  padding-top: 0.25rem;
}

.github, .location, .twitter {
  border-radius: 50%;
}

.github, .twitter, .linkedin, .youtube, .location, .emailIcon {
  transition: color 0.25s ease-in-out;
}

.span:hover .linkedin {
  color: #0072b1;
}

.span:hover .github {
  background: linear-gradient(#0057b7, transparent, #ffd700);
  color: rgba(255,255,255,0.125);
}

.span:hover .youtube {
  color: #FF0000;
}

.span:hover .twitter {
  color: #1DA1F2
}

.locationWrapper:hover .location {
  animation: spinBounce 3s ease-in-out infinite alternate;
  color: white;
}

@keyframes spinBounce {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(-1, 1);
  }

  100% {
      transform: scale(1);
  }
}

.emailButton:hover .emailIcon {
  color: lime;
}

.emailButton {
  background-color: transparent;
  border: none;
  color: silver;
  cursor: copy;
  text-align: start;
  white-space: nowrap;
}

.location {
  margin: 0 1rem 0 0;
}

.emailIcon {
  margin: 0 1rem 0 0;
}

@media (min-width: 690px) {
  .li {
    font-size: 1.25rem;
  }
}

@media (min-width: 400px) and (max-width: 575px) {
  .li {
    font-size: 1.5rem;
  }
}

@media (max-width: 460px) {
  .ul {
    transform: translate(-1rem, 0);
  }
  .li {
    font-size: 1.25rem;
  }
}

@media (max-width: 325px) {
  .li {
    font-size: 1rem;
  }
}