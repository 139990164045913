.rowWrapper {
  padding-block: 6rem;
  transform: translateX(-0.75rem);
  transition: background-color 0.5s ease-in-out;
  width: 100vw;
}

.rowTech {
  padding-block: 4rem;
}

.professional {
  margin-block: 5rem;
}

.recommendsWrapper {
  margin-top: 7rem;
  margin-bottom: 5rem;
  padding-block: 3rem;
  transform: translateX(-0.75rem);
  width: 100vw;
}

.recommends {
  display: flex;
  justify-content: center;
}

@media (max-width: 575px) {
  .home {
    margin-top: -5rem;
  }
}
