.navWrapper {
  display: flex;
  justify-content: flex-end;
  height: 0rem;
  margin-bottom: 3rem;
  transition: all 0.5s ease-in-out;
}

.nav {
  justify-content: flex-end;
  margin-inline: auto;
  background: rgba(0, 0, 0, 0.75);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  text-transform: uppercase;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  width: 100%;
  max-width: 1200px;
  margin-inline: auto;
}

.ul {
  list-style-type: none;
  margin-top: 2.5rem;
}

.a, .button {
  cursor: pointer;
  color: white; 
  display: block;
  padding: 0.75rem 2rem;
  position: relative;
  transition: all 0.25s ease-in-out;
}

.button {
  background-color: transparent;
  border: none;
}

.blog:hover {
  color: white; 
}

.button:after,
.a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.button:hover:after,
.a:hover:after { 
  width: 100%; 
  left: 0; 
}

.menu {
  background: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  border: 2px solid white;
  border-radius: 0;
  color: white;
  height: 2rem;
  margin: 0.25rem 0.5rem 0 0;
  padding: 0 0 1.5rem 0;
  width: 3rem;
  transition: scale opacity 0.25s ease-out;
}

.menuIcon {
  margin-top: 0.125rem;
}

.menuLight {
  border: 2px solid black;
}

.close {
  background-color: transparent;
  border: none;
  float: right;
  height: 2rem;
  margin: 0.25rem 0.75rem;
  transition: scale 0.25s ease-out;
}

.openNav {
  transform: translateX(0%);
}

@media only screen and (max-width: 375px) {
  .nav {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .nav {
    padding-top: 1px;
    width: 50%;
  }
  .navWrapper {
    margin-top: -3rem;
  }
  .ul {
    transform: translate(-10%, 0);
  }
  .button:hover:after,
  .a:hover:after { 
    width: 7rem; 
  }
}

@media only screen and (min-width: 575px) {
  .menu {
    display: none;
  }
  .navWrapper {
    height: 3rem;
    margin-top: 0;
  }
  .nav {
    background: none;
    display: flex;
    height: auto;
    overflow-y: hidden;
    position: unset;
    transform: translateX(0);
    max-width: 1200px;
  }
  .boxShadow {
    background: rgba(0,0,0,0.75) !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    opacity: 0.9;
  }
  .close {
    display: none;
  }
  .ul {
    display: flex;
    margin: 6px auto;
  }
  .a, .button {
    padding: 0.5rem 0.75rem;
  }
}

@media only screen and (min-width: 575px) and (max-width: 650px) {
  .ul {
    margin: 2px auto;
  }
  .a, .button {
    padding: 0.25rem 0.75rem;
  }
  .navWrapper {
    height: 2.25rem;
  }
}

@media only screen and (min-width: 650px) {
  .a, .button {
    padding: 0.3rem 1.5rem;
  }
}

@media only screen and (min-width: 900px) {
  .a, .button {
    padding: 0.3rem 2rem;
  }
}