.hoverImg {
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  margin: 8px auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: fit-content;
}

.hoverImg * {
  box-sizing: border-box;
  transition: all 0.25s ease-in;
}

.hoverImg:before,
.hoverImg:after {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  transition: all 0.3s ease-in-out;
  z-index: 1;
  opacity: 1;
}

.hoverImg img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;

  opacity: 0.7;
  transform: scale(1.2);
}

.figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 5%;
  justify-content: center;
  margin: 0 15%;
  opacity: 1;
  overflow-Y: auto;
  text-align: left;
  transition-delay: 0.1s;
  z-index: 2;
}

.hoverImg:hover:before,
.hoverImg:hover:after {
  opacity: 0;
  transform: scaleY(2);
}

.hoverImg:hover > img {
  opacity: 1;
  transform: scale(1);
}

.hoverImg:hover figcaption {
  opacity: 0;
}

@media (max-width: 1000px) {
  .figcaption {
    margin: auto 0.5rem;
    padding: 0 9%;
  }
}

@media (max-width: 900px) {
  .figcaption {
    margin: 5% auto;
    padding: 0 7%;
  }
}

@media (max-width: 780px) {
  .figcaption {
    margin: 5% auto;
    padding: 0 5%;
  }
}

@media (max-width: 575px) {
  .figcaption {
    margin: 5%;
  }
}

@media (max-width: 340px) {
  .figcaption {
    padding-left: 0.25rem;
    padding-top: 3rem;
  }
}
