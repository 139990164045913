.switch {
  align-items: center;
  display: flex;
  padding-right: 0.5rem;
}

.label {
  cursor: pointer;
  margin: 4px 10px 12px 10px;
}

.label:hover {
  color: lime !important;
}

@media only screen and (max-width: 575px) {
  .displayLarge {
    display: none;
  }
  .switch {
    transform: translateX(1.25rem);
  }
}

@media only screen and (min-width: 575px) and (max-width: 650px) {
  .label {
    margin: 4px 10px;
  }
}

@media only screen and (min-width: 575px) {
  .displaySmall {
    display: none;
  }
    .switch {
      transform: translateX(-0.5rem);
    }
}
