.profilepic {
  border-radius: 50%;
  max-width: 50vw;
  padding: 1rem;
  position: relative;
  width: 20rem;
  z-index: 1;
}

.hello {
  margin-top: 7rem;
  max-width: 1200px;
}

.intro {
  display: flex;
  flex-direction: column;
  padding-left: 4vw;
  text-align: left;
}

.h3 {
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.h5 {
  line-height: 2.25rem;
}

@media (max-width: 815px) {
  .intro {
    gap: 1rem;
    position: relative;
    transform: translate(0, -20%);
    width: 90%;
    z-index: 12;
  }
}

@media (max-width: 575px) {
  .profilepic {
    position: relative;
    z-index: 11;
  }
  .intro {
    transform: translate(0, -10%);
  }
}

@media (max-width: 450px) {
  .intro {
    transform: translate(0%, 0%);
  }
}

@media (max-width: 350px) {
  .intro {
    transform: scale(0.9) translate(0%, 0%);
  }
}
